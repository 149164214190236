.date-filter{
    display: flex;
}

.text-drawer{
    margin: 1%;
}
.date-filter-icons{
    margin: 1%;
}

.option-filter-section {
    margin: 1%;
}