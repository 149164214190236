.dashboard-main-body {
    display: flex;
}

.dashboard-left-body{
    width:86%;
    background: #f1f1f1;

}

.merchant-option-buttons .when-active {
    display: flex;
    margin: 5px;
    width: 25%;
    border-radius: 0 !important;
    background-color: #fffefe;
    color: #1a283d;
    border-bottom-style: solid;
    border-bottom-color: #1a283d;
    border-bottom-width: 5px;
    text-decoration-style: none;
    padding: 10px;
    font-size: 16px;
    cursor: pointer;
}

.merchant-option-buttons .when-not-active {
    display: flex;
    margin: 5px;
    width: 25%;
    border-radius: 0 !important;
    background-color: #f1f1f1;
    color: #1a283d;
    padding: 10px;
    font-size: 16px;
    cursor: pointer;

}
@media (min-width: 1200px)
{.statRow {
    flex: 0 0 auto;
    width: 100%;
}
}