:root {
    /* --primary-color: #2A2A5F; */
    --primary-color: #222e3c;
    --secondary-color: #00B47F;
    --grey-color: #3d4160;
    --white-color: #ffffff;
  }

  
.sidebar {
    /* background-color: var(--primary-color) !important; */
    background-color: #1A283D;
    position: fixed;
    height: 100%;
    width: inherit;
  }
  
  .label-text-drawer {
    display: flex;
    justify-content:center;
    align-items: center;
    height: 55px;
    padding-left: 20%;
  }

  .label-text-drawer:hover:not(.active) {
    background-color: var(--grey-color) !important;
  }

 .sidebar-content .label-text-drawer {
    display: flex;
    flex-direction: row-reverse;
    justify-content: flex-end;
    cursor: pointer;
  }

  .sidebar-content .label-text-drawer .text-drawer a {
    text-align-last: right;
    font-family: Arial, Helvetica, sans-serif;
    font-weight: 500;
    font-size: medium;
    color: #ffffffbe;
    padding: 20px;
    text-decoration: none;
  }

  .sidebar-content .label-text-drawer .icon-arrow p{
    font-weight: bolder;
    font-size: medium;
    margin-top: 100px;
  }
    
  /* Links on mouse-over */
  .sidebar a:hover:not(.active) {
    color: var(--white-color) !important;
  }
  
  /* Page content. The value of the margin-left property should match the value of the sidebar's width property */
  div.content {
    margin-left: 200px;
    padding: 1px 16px;
    height: 1000px;
  }
  
  /* On screens that are less than 700px wide, make the sidebar into a topbar */
  @media screen and (max-width: 700px) {
    .sidebar {
      display: none;
    }
    .sidebar a {
      float: left;
      display: none;
    }
    div.content {margin-left: 0;}
  }
  
  .sidebar-content{
    padding-top: 12%;
  }
  

  /* On screens that are less than 400px, display the bar vertically, instead of horizontally */
   @media screen and (max-width: 400px) {
    .sidebar a {
      display: none;
    }
  } 

  .loader-blank {
    position: absolute;
    left: 50%;
    top: 50%;
    z-index: 1;
    width: 150px;
    height: 150px;
    margin: -75px 0 0 -75px;
    border: 16px solid #f3f3f3;
    border-radius: 50%;
    border-top: 16px solid #4e5ff9;
    width: 120px;
    height: 120px;
    -webkit-animation: spin 2s linear infinite;
    animation: spin 2s linear infinite;
  }

  .user-information{
    text-align:center;
    position:absolute;
    margin-left:17%;
    display: flex;
  }

  .user-icon{
    width:50%;
  }

  .user-icon img{
    width:70%;
  }

  .user-info-text{
    /* width: 100%; */
    margin: 12px;
    white-space: nowrap; 
    width: 100px; 
    overflow: hidden;
    text-overflow: ellipsis; 
  }

  .user-info-text p{
      margin-bottom: 0;
      margin-top: 0;
      color: white;
  }


.sidebar-header {
  padding: 15%; 
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.sidebar-header .user-pic {
  float: left;
  width: 60px;
  padding: 2px;
  border-radius: 12px;
  /* margin-right: 15px; */
  overflow: hidden;
}

.sidebar-header .user-pic img {
  object-fit: cover;
  height: 100%;
  width: 100%;
  border-radius: 50%;
}

.sidebar-header .user-info {
  float: left;
}

.sidebar-header .user-info > span {
  display: block;
  color: white;
}

.sidebar-header .user-info .user-role {
  font-size: 12px;
}


  /* iPads  portrait and landscape*/
  @media only screen 
  and (min-device-width: 768px) 
  and (max-device-width: 1024px) 
  and (-webkit-min-device-pixel-ratio: 2) {
  
    .sidebar {
      display: none;
    }
  
  }
  
