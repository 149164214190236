:root {
    --primary-color: #2A2A5F;
    --secondary-color: #00B47F;
    --grey-color: #3d4160;
    --white-color: #ffffff;
    --common-button: #4e5ff9;
  }

.login-wraper{
    display: flex;
    flex-direction: column;
    height: 100vh;
    align-items: center;
    justify-content: center;
    background-color: #ffffff;
    overflow-x: hidden; 
    overflow-y: scroll; 
}

.login-wraper .login-form-sec{
    display: flex;
    flex-direction: column;
    justify-content: center;
    
    background-color: white;
    width: 100%;
    border-block-start-color: var(--common-button);
    border-block-start-style: solid;
    border-block-start-width: 4px;
}

.login-wraper .login-form-sec .loader {
    margin-left: 40%;
    border: 16px solid #f3f3f3;
    border-radius: 50%;
    border-top: 16px solid var(--common-button);
    width: 50px;
    height: 50px;
    -webkit-animation: spin 2s linear infinite; /* Safari */
    animation: spin 2s linear infinite;
  }
  
  /* Safari */
  @-webkit-keyframes spin {
    0% { -webkit-transform: rotate(0deg); }
    100% { -webkit-transform: rotate(360deg); }
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }

.login-form-container {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.login-form-container .login-form-inner {
    display: flex;
}

.login-form-container .login-form-sec {
    width: 408px;
}

.login-form-container .login-form-inner img {
  height: 60px;
  width: 60px;
}

.login-form-container p {
    font-size: 22;
    font-weight: bold;
}

.login-form-container  input {
    font-size: 18px;
    margin: 10px 0 20px 0;
    max-width: 100%;
    padding: 10px 14px;
    box-shadow: rgba(50, 50, 93, 0.14902) 0px 1px 3px,
      rgba(0, 0, 0, 0.0196078) 0px 1px 0px;
    border-radius: 4px;
    background: white;
    color: var(--primary-color);
    letter-spacing: 0.025em;
    width: 100%;
  }

  .registration-form-container  input {
    font-size: 18px;
    margin: 5px 0 4px 0;
    max-width: 100%;
    padding: 10px 14px;
    box-shadow: rgba(50, 50, 93, 0.14902) 0px 1px 3px,
      rgba(0, 0, 0, 0.0196078) 0px 1px 0px;
    border-radius: 4px;
    background: white;
    color: var(--primary-color);
    letter-spacing: 0.025em;
    width: 100%;
  }

  .registration-form-dropdown select{
    font-size: 16px;
    margin: 5px 0 4px 0;
    max-width: 100%;
    padding: 10px 14px;
    box-shadow: rgba(50, 50, 93, 0.14902) 0px 1px 3px,
      rgba(0, 0, 0, 0.0196078) 0px 1px 0px;
    border-radius: 4px;
    background: white;
    color: var(--common-button);
    letter-spacing: 0.025em;
    width: 100%;
  }

  .registration-form-container label {
    color: #6b7c93;
    font-weight: 300;
    letter-spacing: 0.025em;
    margin-top: 12px;
    display: block;
}

.phone-number-div .react-tel-input .country-list {
  outline: none;
  z-index: 1;
  list-style: none;
  position: unset;
  padding: 0;
  margin: 10px 0 10px -1px;
  box-shadow: 1px 2px 10px rgba(0,0,0,0.35);
  background-color: white;
  width: 300px;
  max-height: 200px;
  overflow-y: scroll;
  border: 1px solid #CACACA;
  border-radius: 5px;
  border-radius: 0 0 3px 3px;
}

.phone-number-div  .react-tel-input .form-control {
  position: relative;
  font-size: 14px;
  letter-spacing: .01rem;
  margin-top: 0 !important;
  margin-bottom: 0 !important;
  padding-left: 48px;
  margin-left: 0;
  background: #FFFFFF;
  border: 1px solid #CACACA;
  border-radius: 5px;
  line-height: 25px;
  height: auto;
  width: inherit;
  outline: none;
}

._9ay7 {
  color: #f02849;
  font-family: SFProText-Regular, Helvetica, Arial, sans-serif;
  font-size: 13px;
  line-height: 16px;
  margin-top: 8px;
  text-align: left;
}

._sucess {
  color: #29ec18;
  font-family: SFProText-Regular, Helvetica, Arial, sans-serif;
  font-size: 13px;
  line-height: 16px;
  margin-top: 8px;
  text-align: left;
}

.phone-number-div  ul li {
  width: 100%;
}


/* For mobile phones */

@media (max-width:768px)
{
  .login-form-container .login-form-sec {
    width: 348px;
}

}