:root {
    --primary-color: #2A2A5F;
    --secondary-color: #00B47F;
    --grey-color: #3d4160;
    --white-color: #ffffff;
  }
  
  * {
      box-sizing: border-box;
    }
    
    .campaign-parent-container {
      background-color: #fff;
      /* height: 400vh; */
    }
  
    .campaign-detail-container {
      display: flex;
      justify-content: space-between;
      background-color: #fff;
      margin-left: 10px;
      width: 60%;
    }
    .top-right {
      position: absolute;
      top: 8px;
      right: 16px;
    }
  
    /* .campaign-parent{
      display: flex;
      flex-direction: "column";
    } */
  
   
    
    .close {
      cursor: pointer;
      position: absolute;
      top: 50%;
      right: 0%;
      padding: 12px 16px;
      transform: translate(0%, -50%);
    }
    
    .close:hover {background: #bbb;}
  
  .createcampaignblock{
     width: fit-content;
      padding: 20px;
      margin-top: 25px;
      margin-left: 254px;
      background: #fff;
      box-shadow: 0 2px 5px #ccc;
  }
  
  .campaign-content-body{
      display: flex;
      flex-wrap: wrap;
      width: 85%;
      height: 100%;
  }
  
  .campaign-right-body {
    display: flex;
    flex-wrap: wrap;
    margin-left: 15%;
  }
  
  .campaign-status-card {
      box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
      transition: 0.3s;
      border-radius: 5px;
      margin-top: 78px;
      display: flex;
      width: 46%;
      height: 170px;
      margin: 20px;
  }
  
  .header-top{
    width: 100%;
    z-index: 1000;
    height: 15%;
    background-color: mediumpurple;
    /* position: fixed; */
  }
  
  .dot-indicatior-active{
    height: 18px;
    width: 18px;
    background-color: #008000;
    border-radius: 50%;
    display: inline-block;
    padding: 4px;
  }
  
  .dot-indicatior-notactive{
    height: 18px;
    width: 18px;
    background-color: #eb7311;
    border-radius: 50%;
    display: inline-block;
    padding: 4px;
  }
  
  .body-bottom{
    height: 90%;
    display: flex;
    flex-direction: "column";
  }
  
  .card-campaign-image{
    max-width: 40%;
    max-height: 100%;
    min-width: 40%;
    min-height: 100%;
    width: 40%;
    height: 100%;
  }
  
  .card-image-campaign {
    display: block;
    width: 100%;
    height: auto;
    overflow: hidden;
    position: relative;
    cursor: pointer;
  } 
  
  .card-image-campaign img {
    width: 100%;
    height: 143px;
    vertical-align: middle;
    border: 0;
    border-radius: 0;
  }
  .campaign-detail-text{
      height: 39px;
      display: block!important;
      display: -webkit-box!important;
      -webkit-line-clamp: 2;
      -moz-line-clamp: 2;
      -ms-line-clamp: 2;
      -o-line-clamp: 2;
      line-clamp: 2;
      -webkit-box-orient: vertical;
      -moz-box-orient: vertical;
      -ms-box-orient: vertical;
      -o-box-orient: vertical;
      box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: normal;
  }
  .payment-modal {
    display: block;
    position: fixed;
    z-index: 1; 
    left: 0;
    top: 0;
    padding: 200px;
    width: 100%;
    height: 100%; 
    overflow: auto;
    background-color: rgb(0,0,0); 
    background-color: rgba(0,0,0,0.4);
  }
  
  .date-form-container {
    margin: 20px;
  }
  
  .date-form-container form {
    background-color: #f6f6f6;
  }
  
  .campaign-card-with-dot {
    display: inline-block;
    vertical-align: top;
    background-color: #fff;
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
    transition: 0.3s;
    width: 30.5%;
    /* height: 274px; */
    margin: 10px;
    border-radius: 5px;
  }
  
  .schedule-now-btn {
    background-color: var(--primary-color);
    height: 60px;
    width: 200px;
    text-justify: center;
    justify-content: space-around;
    margin-left: 35%;
    margin-top: 5%;
  }
  
  .card-campaign{
      padding-left: 2%;
      padding-top: 2%;
      padding-bottom: 2%;
      height: 200px;
      width: 28%;
  }
  
  
    
  .loader-blank{
    position: absolute;
    left: 50%;
    top: 50%;
    z-index: 1;
    width: 150px;
    height: 150px;
    margin: -75px 0 0 -75px;
    border: 16px solid #f3f3f3;
    border-radius: 50%;
    border-top: 16px solid #4e5ff9;
    width: 120px;
    height: 120px;
    -webkit-animation: spin 2s linear infinite;
    animation: spin 2s linear infinite;
  }
  
  .campaign-information-block {
    font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
    display: inline-block;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    padding-right: 10px;
    padding-left: 10px;
    max-width: 30ch;
  
  }
  .campaign-information-block p{
  
      margin-top: 0;
      margin-bottom: 1rem;
      font-family: sans-serif;
      font-weight: bold;
      font-size: 14px;
  
  }
  
  .both-location-container{
    display: flex;
    justify-content: space-around;
  }
  
  .location-selector-1{
    width: 45%;
  }
  
  .location-selector-2{
    width: 45%;
  }
  
  
  .campaign-details-container .tooltiptext {
    visibility: hidden;
    opacity:0.8;
    width: 120px;
    background-color: black;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 5px 0;
  
    /* Position the tooltip */
    position: absolute;
    z-index: 5;
  }
  
  .campaign-details-container:hover .tooltiptext {
    visibility: visible;
  }
  
  
  /* For mobile phones */
  
  @media (max-width:768px)
  {
    .parent{
      display: flex;
      /* justify-content: space-between; */
      flex-direction: column;
      align-items: flex-end;
    }
  
    .card{
      box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
      transition: 0.3s;
      width: 88%;
      border-radius: 5px;
      margin-top: 78px;
      /* margin-left: 300px; */
      display: flex;
      justify-content: center;
    }
  
   
  
  .createcampaignblock{
    width: fit-content;
    padding: 20px;
    margin-top: 25px;
    margin-left: 0;
    background: #fff;
    box-shadow: 0 2px 5px #ccc;
  }
  .header-top{
    /* width: 100%;
    z-index: 1000;
    height: 15%;
    background-color: mediumpurple;
    position: fixed; */
    display: none;
  }
  /* .campaign-details-container{
    background-color: white;
  } */
  
  .campaign-details-container p {
  font-size: larger;
  font-weight: 600;
  }
  
  .drawer-window-campaignpage{
    display: none;
  }
  
  .campaign-content-body {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    height: 100%;
  }
  
  .campaign-card-with-dot{
    background-color: #fff;
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
    transition: 0.3s;
    width: 100%;
    height: 274px;
    margin: 3%;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .campaign-information-block {
    font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
    display: flex;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    padding-right: 10px;
    padding-left: 10px;
  
  }
  .campaign-information-block p{
  
    margin-top: 0;
    margin-bottom: 1rem;
    font-family: sans-serif;
    font-weight: bold;
    font-size: 14px;
  
  }
  
  .both-location-container{
    display: flex;
    flex-direction: column;
  }
  
  .location-selector-1{
    width: 100%;
  }
  
  .location-selector-2{
    width: 100%;
  }
  
  
  .date-form-container form {
    background-color: #f6f6f6;
    display: flex;
    flex-direction: column;
    width: 100%;
  }
  
  
  }
  
  .common-button{
      height: 52px;
      width: 140px;
      margin: 10px;
      background-color: #4e5ff9;
  }

  .option-status{
    float:right;
  }