
.merchant-sidebar{
    width:14%;
}

.merchant-right-input-bar {
    display: flex;
    width: 100%;
    justify-content: center;
}

.merchant-right-body{
    width: 86%;
    display: flex;
    flex-direction: column;
}

.merchant-page-main {
    display: flex;
}

.merchant-right-input-bar input{
    font-size: 18px;
    margin: 10px 0 20px 0;
    max-width: 100%;
    padding: 10px 14px;
    box-shadow: rgb(50 50 93 / 15%) 0px 1px 3px, rgb(0 0 0 / 2%) 0px 1px 0px;
    border-radius: 4px;
    background: white;
    letter-spacing: 0.025em;
    width: 35%;
    
}

.merchant-option-buttons .when-active {
    display: flex;
    margin: 5px;
    width: 25%;
    border-radius: 0 !important;
    background-color: #f1f1f1;
    color: #1a283d;
    border-bottom-style: solid;
    border-bottom-color: #1a283d;
    border-bottom-width: 5px;
    text-decoration-style: none;
    padding: 10px;
    font-size: 16px;
    cursor: pointer;
}

.merchant-option-buttons .when-not-active {
    display: flex;
    margin: 5px;
    width: 25%;
    border-radius: 0 !important;
    background-color: #f1f1f1;
    color: #1a283d;
    padding: 10px;
    font-size: 16px;
    cursor: pointer;

}

.merchant-option-buttons {
    display: flex;
}

.loggedin-text{
    margin: 1%;
}