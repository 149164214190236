.w-100 {
    width: 100%!important;
}

.card-row-pr {
    display: flex;
    justify-content: space-evenly;
}
.card-row-se {
    display: flex;
    justify-content: space-evenly;
}

.row {
    /* --bs-gutter-x: 24px;
    --bs-gutter-y: 0; */
    display: flex;
    flex-wrap: wrap;
    /* margin-top: calc(var(--bs-gutter-y)*-1);
    margin-right: calc(var(--bs-gutter-x)/-2);
    margin-left: calc(var(--bs-gutter-x)/-2); */
}

.card {
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 25%;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: initial;
    border: 0 solid transparent;
    border-radius: .25rem;
    margin-bottom: 24px;
    box-shadow: 0 0 0.875rem 0 rgba(33,37,41,.05);
    min-height: 46%;
    cursor: pointer;

}

.card-clicked {
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 25%;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: initial;
    border: 0 solid transparent;
    border-radius: .25rem;
    margin-bottom: 24px;
    box-shadow: 4px 4px 6px 6px #cbcbcb;
    min-height: 46%;
    cursor: pointer;

}
@media (max-width: 576px)
{
    .card {
        position: relative;
        display: flex;
        flex-direction: column;
        min-width: 0;
        word-wrap: break-word;
        background-color: #fff;
        background-clip: initial;
        border: 0 solid transparent;
        border-radius: .25rem;
        /* margin-bottom: 24px; */
        box-shadow: 0 0 0.875rem 0 rgba(33,37,41,.05);
        margin-left: 10%;
        cursor: pointer;
    }

    .card-clicked {
        cursor: pointer;

        position: relative;
        display: flex;
        flex-direction: column;
        min-width: 0;
        word-wrap: break-word;
        background-color: #fff;
        background-clip: initial;
        border: 0 solid transparent;
        border-radius: .25rem;
        /* margin-bottom: 24px; */
        box-shadow: 4px 4px 6px 6px #cbcbcb;
        margin-left: 10%;
    }
}

.card-body {
    display: flex;
    flex: 1 1 auto;
    padding: 1.25rem;
    justify-content: space-around;
}

@media (min-width: 576px){

.col-sm-6 {
    flex: 0 0 auto;
    width: 25%;
    height: 20%;
}

}

@media (min-width: 1200px){
.col-xl-6 {
    flex: 0 0 auto;
    width: 50%;
}

.data-line-chart-component {
    background: white;
    flex: 0 0 auto;
    width: 47%;
    margin-bottom: 11px;
    box-shadow: 0 0 0.875rem 0 rgba(33,37,41,.05);
}

}

@media (max-width: 768px){
    .data-line-chart-component {
        background: white;
        flex: 0 0 auto;
        width: 100%;
        margin-bottom: 24px;
        box-shadow: 0 0 0.875rem 0 rgba(33,37,41,.05);
    }
}

.data-line-chart-component .chartjs-render-monitor{
    width:100%
}



.bg-primary-light {
    background: #d3e2f7;
}

.rounded-circle {
    border-radius: 50%!important;
}

.avatar-title {
    display: flex;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;
    color: #3b7ddd;
}



