
.account-history-main-body{
    display: flex;
}

.history-navigation-options ul{
    top: auto;
    left: auto;
    position: relative;
    display: block;
    max-height: none;
    box-shadow: none;
    border-radius: 0;
    margin: 0;
    padding: 0;
    right: 0px;
    bottom: 0px;
    z-index: 0;
    margin-block-end: 0px;
}

.account-history-main-body li{
    padding: 0;
    display: block;
    margin: 12px 0 7px;
    list-style: none;
    margin-bottom: 10px;
    width: -moz-fit-content;
    /* width: fit-content; */
    border: none;
    background-color: transparent;
    animation: none;
    /* transition: transform .2s;*/
}

.account-history-main-body a{
    border-bottom: 4px solid transparent;
    padding: 0 0 1px;
    color: #71738f;
    display: inline-block;
    background-color: transparent!important;
    font-size: 14px;
}

.history-table{
    width:85%;
}
.purchase-history-table .griddle-table-heading-cell
{
    cursor: pointer;
    width: auto;
}

.purchase-history-table .griddle-settings-toggle{
    display: none;
}

.purchase-history-table .griddle-pagination{
    display: flex;
}

.purchase-history-table button{
    display: inline-block;
    font-size: 18px;
    margin: 0 5px;
    padding: 0 5px;
    color: #649;
    background: transparent;
    border: 1px solid #649;
    height: 32px;
    width: auto;
}

.purchase-history-table select {
    display: inline-block;
    font-size: 18px;
    margin: 0 5px;
    padding: 0 5px;
    color: #649;
    background: transparent;
    border: 1px solid #649;
    height: 32px;
    width: auto;
}

.griddle-pagination{
    justify-content: flex-end;
}

th {
    text-align: left;
    border-bottom: 1px solid hsla(0,0%,0%,0.12);
    font-feature-settings: tnum;
    padding-left: 1rem;
    padding-right: 1rem;
    padding-top: 0.75rem;
    padding-bottom: calc(0.75rem - 1px);
}

td {
    text-align: left;
    border-bottom: 1px solid hsla(0,0%,0%,0.12);
    font-feature-settings: tnum;
    padding-left: 1rem;
    padding-right: 1rem;
    padding-top: 0.75rem;
    padding-bottom: calc(0.75rem - 1px);
}
/* for mobile phones */
@media only screen and (max-width: 760px),
(min-device-width: 768px) and (max-device-width: 1024px)  {

    .history-table{
        width:100%;
        overflow: auto;
    }

}