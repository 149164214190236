.floating-text-form{
    display: flex;
    flex-wrap: wrap;
}

.block{
    display:flex;
    justify-content:space-between;
    width:-webkit-fill-available;
}

.block-header{
    display:flex;
    justify-content: space-between;
    width: 100%;
}

.block label{
    display: flex;
    flex-direction: column;
    align-items: end;
    margin: 1%;
}